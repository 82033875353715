"use client";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FontProvider } from '@web/components/font-provider';
import { SuspendedPostHogPageView } from '@web/components/phosthog/post-hog-page-view';
import { captureError } from '@web/utils/error-utils';
import type { NextFont } from 'next/dist/compiled/@next/font';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import type { FC, ReactNode } from "react";


const queryClient = new QueryClient();
type Props = {
  children: ReactNode,
  font: NextFont
}

export const Providers: FC<Props> = function ({ children, font }) {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_POSTHOG_KEY || !process.env.NEXT_PUBLIC_POSTHOG_HOST) {
      const err = new Error(`Can't init PostHog : NEXT_PUBLIC_POSTHOG_KEY or NEXT_PUBLIC_POSTHOG_HOST are not defined`);
      captureError(err);
      return;
    }

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: '/ingest', // reverse proxy with the NextJS middleware
      ui_host: 'https://eu.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    });
  }, []);

  return <QueryClientProvider client={queryClient}>
    <PostHogProvider client={posthog}>
      <SuspendedPostHogPageView />

      <FontProvider font={font}>
        {children}
      </FontProvider>
    </PostHogProvider>
  </QueryClientProvider>;
};

