"use client";

import type { NextFont } from "next/dist/compiled/@next/font";
import { createContext, useContext } from "react";
import type { FC, ReactNode } from "react";

const FontContext = createContext<NextFont | null>(null);

export function useFont() {
  return useContext(FontContext);
}

type Props = {
  children: ReactNode,
  font: NextFont
}

export const FontProvider: FC<Props> = function({font, children}) {
  return <FontContext.Provider value={font}>
    {children}
  </FontContext.Provider>
};